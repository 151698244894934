/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@700&family=Fira+Code&family=Fira+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5 {
	font-family: "Barlow", "Fira Sans", sans-serif;
}

body {
	font-family: "Inter", sans-serif;
}

.new-announcement-bar-container {
	color: #16253b;
	background: rgba(194, 170, 170, 0.5);
	padding: 0 !important;
	line-height: 26px;
	text-align: center;

	margin-left: -33.33%;
	margin-right: -33.33%;
}


.announcement-bar-container {
	color: #16253b;
	align-items: center;
	justify-content: space-between;
	margin: 0 14rem 2rem;
	border-radius: 4px;
	box-shadow: 1px 1px 25px 3px rgba(149, 136, 124, 0.72);
	line-height: 30px;
}

.wrapper {
	display: flex;
	flex-flow: row wrap;
}

.wrapper > * {
	flex: 1 100%;
}

.aside-2 {
	color: rgba(22, 37, 59, 0.67);
	text-align: right;
	padding-right: 10px;
	padding-top: 2px;
}

@media all and (min-width: 600px) {
	.aside {
		flex: 1 0 0;
	}
}

@media all and (min-width: 800px) {
	.main {
		flex: 5 0px;
	}

	.aside-1 {
		order: 1;
	}

	.main {
		order: 2;
	}

	.aside-2 {
		order: 5;
	}
}
